<template>
    <v-main style="min-height: 80% !important">
        <v-container class="pb-12">
            <v-row class="mt-4">
                <v-col cols="12">
                    <h2
                        :style="{ color: parameters.primaryColor }"
                        class=" mb-3 font-weight-regular"
                    >
                        Como podemos Ajudar?
                    </h2>
                    <v-subheader class="justify-center subtitle-2">Aqui temos algumas dúvidas frequentes que podem te ajudar.</v-subheader>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-expansion-panels focusable>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="font-weight-light" :style="{ color: parameters.primaryColor }">Como altero minha senha?</v-expansion-panel-header>
                            <v-expansion-panel-content class="text-left body-2 font-weight-light">
                                <br>
                                <p>Para alterar a sua senha, acesse a <a href="/login" :style="{ color: parameters.secondaryColor }">página de login</a> e clique em "Esqueci minha senha". Para poder recuperar a sua senha, será necessário informar o login de acesso (no caso seu CNPJ).</p>
                                <p>Clique em prosseguir e você receberá por e-mail uma senha provisória de acesso.</p>
                                <p>No e-mail você receberá um link onde será direcionado para a página de recuperação de senha. Informe a senha provisória e cadastre uma nova senha.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="font-weight-light" :style="{ color: parameters.primaryColor }">Solicitei meu cadastro e agora?</v-expansion-panel-header>
                            <v-expansion-panel-content class="text-left body-2 font-weight-light">
                                <br>
                                <p>Ao solicitar cadastro no nosso e-commerce, você recebe por e-mail uma confirmação de cadastro.</p>
                                <p>Mas porquê isso? Essa confirmação é necessária para ativarmos o seu cadsatro.</p>
                                <p>No e-mail você receberá as instruções para acessar o link de confirmação de cadastro, onde será necessário informar a senha de acesso informada juntamente com seus dados durante o formulário de cadastro.</p>
                                <p>Após você realizar o processo de confirmação, receberemos a notificação do seu cadastro para aprovação. Nós da {{ parameters.store.cf1fant }} vamos analisar a sua solicitação. Caso seu cadastro seja aprovado, você receberá um e-mail de notificação, confirmando seu acesso.</p>
                                <p>Caso você não receba esse e-mail, você pode entrar em contato com nosso atendimento para esclarecer dúvidas.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="font-weight-light" :style="{ color: parameters.primaryColor }">Não estou recebendo os e-mails enviados pelo sistema</v-expansion-panel-header>
                            <v-expansion-panel-content class="text-left body-2 font-weight-light">
                                <br>
                                <p>Caso você não esteja recebendo os e-mails enviados pelo sistema, verifique a sua caixa de <i>span</i>.</p>
                                <p>Se mesmo assim você não encontrar os e-mails, entre em contato com nossos canais de atendimento para conferirmos o seu e-mail cadastrado.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="font-weight-light" :style="{ color: parameters.primaryColor }">Como altero meu endereço de entrega?</v-expansion-panel-header>
                            <v-expansion-panel-content class="text-left body-2 font-weight-light">
                                <br>
                                <p>Para alterar o endereço de entrega dos seu cadastro, é necessário <a href="/login" :style="{ color: parameters.secondaryColor }">realizar o login</a>, e acessar o seu cadastro.
                                <p>No formulário, localize o título "Endereço de entrega" ao final do formulário. Caso você já tenha um endereço cadastrado, altere o CEP informado caso queira atualizar a informação. Se você ainda não possui um endereço cadastrado, clique em "Cadastrar um Endereço de entrega" e informe o CEP e complemento.</p>
                                <p>Após clique em "Alterar Dados" e o seu endereço já estará salvo no sistema.</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                </v-expansion-panels>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
export default {
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
    }
};
</script>

<style>
</style>